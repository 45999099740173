<template>
  <div class="consulta-dni static" v-cloak>
    <h2 class="title">Consulta de líneas</h2>
    <span class="form-text">
      Asegura tu tranquilidad. Verifica las
      <br />líneas de telefonía móvil que están <br />a tu nombre.
    </span>

    <div v-if="step == 1" class="form-box">
      <a-spin v-if="loading" size="large" />
      <div class="line-list" v-if="data.length > 0 && !loading">
        <span class="line-list-text">
          Tienes
          <strong>{{ data.length }} Línea(s)</strong> registrada(s) asociada(s)
          a tu
          <strong>{{ selectedName.name }}</strong>
        </span>
        <!--  <span class="line-list-text">Actualizado al 05/11/2019 03:00:58</span> -->
        <table>
          <tr class="line-list-tr">
            <th class="line-list-first">Numero</th>
            <th class="line-list-last">Modalidad</th>
          </tr>
          <tr v-for="item in data" :key="item.phone">
            <td class="line-list-first">{{ item.phone }}</td>
            <td class="line-list-last">Prepago</td>
          </tr>
        </table>
      </div>
      <div class="empty" v-if="data.length == 0 && !loading">
        No se encontraron resultados para tu búsqueda.
      </div>
      <CuyButton
        :onSubmit="newSearch"
        title="Nueva Consulta"
        borderRadius="20px"
      />
    </div>

    <div class="form-box" v-if="step == 0">
      <a-select
        size="large"
        :loading="isLoading"
        style="width: 100%"
        placeholder="Tipo de documento"
        @change="onChangeSelect"
      >
        <a-select-option
          v-for="type in idTypes"
          :key="type.id"
          :value="type.id"
          >{{ type.name }}</a-select-option
        >
      </a-select>
      <a-input
        style="border: 1px solid #d9d9d9;"
        size="large"
        placeholder="Número de documento"
        v-model="dni"
      />
      <vue-recaptcha
        ref="recaptcha"
        style="justify-self: center;"
        sitekey="6LfWSmcUAAAAAGQy1M3MmmFZvXIDQLg68NDBIuE9"
        :loadRecaptchaScript="true"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
      ></vue-recaptcha>
      <CuyButton :onSubmit="onSubmit" title="consultar" borderRadius="20px" />
    </div>
  </div>
</template>

<script>
import CuyButton from "../../components/CuyButton";
import VueRecaptcha from "vue-recaptcha";
import { mapActions } from "vuex";
export default {
  name: "LinesByDni",
  components: {
    CuyButton,
    VueRecaptcha
  },
  mounted() {
    this.fetchTypes();
  },
  data() {
    return {
      step: 0,
      dni: "",
      token: "",
      selectedValue: null
    };
  },
  computed: {
    idTypes() {
      return this.$store.getters.idTypes;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    data() {
      return this.$store.getters.numbersList;
    },
    selectedName() {
      return this.$store.getters.idTypes.find(
        item => item.id == this.selectedValue
      );
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  methods: {
    ...mapActions(["fetchTypes", "getNumbersByDni"]),
    onChangeSelect(value) {
      this.selectedValue = value;
    },
    onSubmit() {
      if (this.dni == "" || this.selectedValue == "") {
        this.$message.error("Por favor, llene todos los campos.");
        return;
      }
      if (this.token == "") {
        this.$message.error("Confirmar recapcha");
        return;
      }
      const payload = {
        dni: this.dni,
        "g-recaptcha-response": this.token,
        userIdentificationType: this.selectedValue
      };

      this.getNumbersByDni(payload);
      this.step = 1;
      this.token = "";
    },
    onCaptchaVerified(token) {
      this.token = token;
    },
    newSearch() {
      this.step = 0;
      this.dni = "";
    },
    onCaptchaExpired() {
      this.token = "";
      this.$refs.recaptcha.reset();
    }
  }
};
</script>

<style lang="less">
.consulta-dni {
  padding-top: 2rem;
  text-align: center;
  display: grid;
  gap: 2rem;
  .ant-spin-dot i {
    background-color: #ff7a15;
  }

  h2 {
    font-family: "Bungee", cursive;
    color: #132d2f;
    font-size: 24px;
    margin: 0;
  }

  .form-text {
    color: #7e7e7e;
  }

  .form-box {
    width: 470px;
    border-radius: 8px;
    border: 2px solid #d4408f;
    padding: 2rem 3rem;
    display: grid;
    gap: 1rem;
    justify-self: center;
  }
}

.line-list {
  color: black;

  justify-self: center;
  display: grid;
  gap: 20px;
  margin-bottom: 20px;

  &-tr {
    background: #b2b2b2;
  }

  &-first {
    padding-left: 30px;
    text-align: start;
  }

  &-last {
    padding-left: 50px;
    text-align: center;
  }
  &-text {
    justify-self: center;
    color: #707070;
  }
}

table {
  width: 100%;
  border-spacing: 0px;
  border: 1px solid #b2b2b2;
}
tr {
  height: 33px;
}

.empty {
  text-align: center;
  color: #707070;
}
</style>
